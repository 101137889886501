import { useEffect, useState } from 'react'

import { ConnectWalletButton } from './button'
import { ConnectWalletProviders } from './providers'

import type { ButtonVariant, IconName } from '~/tenset-components'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalMain,
  ModalTitle,
} from '~/tenset-components'
import { useWallet } from '~/tenset-web3'

interface ConnectWalletProps {
  buttonConnectLabel?: string
  buttonIcon?: IconName
  variant?: ButtonVariant
  onConnect?: () => void
}

export function ConnectWallet({
  buttonConnectLabel,
  buttonIcon,
  variant,
  onConnect,
}: ConnectWalletProps) {
  const [isSelectingProvider, setIsSelectingProvider] = useState(false)

  const { wallet } = useWallet()

  useEffect(() => {
    const subscription = wallet?.changed$.subscribe(() => {
      if (wallet.isConnected) {
        setIsSelectingProvider(false)

        onConnect?.()
      }
    })

    return () => subscription!.unsubscribe()
  }, [])

  return (
    <>
      <ConnectWalletButton
        connectLabel={buttonConnectLabel}
        icon={buttonIcon}
        onMultipleProviders={() => setIsSelectingProvider(true)}
        variant={variant}
      />

      <Modal
        open={isSelectingProvider}
        onClose={() => setIsSelectingProvider(false)}
      >
        <ModalContent withPadding={false}>
          <ModalHeader className="sr-only">
            <ModalTitle>Connect wallet</ModalTitle>
          </ModalHeader>

          <ModalMain>
            <ConnectWalletProviders />
          </ModalMain>
        </ModalContent>
      </Modal>
    </>
  )
}
