import type { IconName } from '~/tenset-components'
import { Button, ButtonVariant, Icon } from '~/tenset-components'
import { useWallet, WalletProvider, addressShortener } from '~/tenset-web3'

export interface ConnectWalletButtonProps {
  onMultipleProviders: () => void
  connectLabel?: string
  icon?: IconName
  variant?: ButtonVariant
}

export function ConnectWalletButton({
  onMultipleProviders,
  connectLabel = 'Connect wallet',
  icon,
  variant,
}: ConnectWalletButtonProps) {
  const { wallet, connect, disconnect } = useWallet()

  const walletFormatted = addressShortener(wallet?.account || '')

  const label = wallet?.isConnected
    ? `Disconnect ${walletFormatted} wallet`
    : connectLabel

  const onConnect = async () => {
    if (window.ethereum || window.okexchain) {
      onMultipleProviders()

      return
    }

    await connect(WalletProvider.WalletConnect)
  }

  const onDisconnect = async () => {
    await disconnect()
  }

  return (
    <Button
      onClick={() => (wallet?.isConnected ? onDisconnect() : onConnect())}
      variant={
        variant ??
        (wallet?.isConnected ? ButtonVariant.Secondary : ButtonVariant.Primary)
      }
    >
      {label}

      {icon && <Icon name={icon} />}
    </Button>
  )
}
